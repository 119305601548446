import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { accountAPI } from '~/api/account'
import { invitationAPI } from '~/api/invitation'
import { Head } from '~/components/head'
import { AlertBox } from '~/components/ui/alert-box'
import { Button } from '~/components/ui/button'
import { Checkbox } from '~/components/ui/checkbox'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip'
import { config } from '../../../config'


interface FormInputs {
  email: string
  password: string
  firstName: string
  lastName: string
  acceptToS: boolean
  acceptEula: boolean
}

export default function CreateAccountPage() {
  const { t } = useTranslation([ 'auth', 'general' ])
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [ searchParams ] = useSearchParams()

  // Find the invitation
  const invitationId = searchParams.get('invitation') ?? ''
  const { data: invitation } = useSuspenseQuery({
    queryKey: [ 'invitations', invitationId ],
    queryFn: () => invitationAPI.getSafe(invitationId)
  })

  // Hide or show password
  const [ hidePassword, setHidePassword ] = useState(true)

  // Form state
  const form = useForm<FormInputs>({
    defaultValues: {
      email: invitation?.email ?? '',
      password: '',
      firstName: '',
      lastName: '',
      acceptEula: false,
      acceptToS: false
    }
  })

  // Check if the email address is available to use before creating the account
  const emailCheckMutation = useMutation({
    mutationKey: [ 'auth', 'email-check' ],
    mutationFn: (inputs: FormInputs) => accountAPI.checkEmailAvailability(inputs.email),
    onSuccess: (result, inputs) => {
      if (result.available) {
        createAccountMutation.mutate(inputs)
      }
    }
  })

  // Create the account
  const createAccountMutation = useMutation({
    mutationKey: [ 'auth', 'create-account' ],
    mutationFn: (inputs: FormInputs) => accountAPI.signUp({
      invitation: invitation!.id,
      email: invitation!.email,
      password: inputs.password,
      firstName: inputs.firstName.trim(),
      lastName: inputs.lastName.trim()
    }),
    onSuccess: () => {
      queryClient.clear()
      navigate('/')
    }
  })

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">

      <Head title={t('create_account.page_title')} />

      <div className="sm:mx-auto sm:w-full sm:max-w-sm -mt-8">
        <Link to="../login">
          <img
            src="https://cleverev.net/assets/logo.svg"
            alt={t('general:logo_alt')}
            className="mx-auto h-12 w-auto"
          />
        </Link>
        <h1 className="mt-10 mb-10 text text-xl text-center font-bold">
          {t('create_account.heading')}
        </h1>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(data => emailCheckMutation.mutate(data))} className="space-y-6">

          {!invitation && (
            <AlertBox error title={t('create_account.error_invalid_invite')} />
          )}

          {(emailCheckMutation.isSuccess && !emailCheckMutation.data.available) && (
            <AlertBox error title={t('create_account.error_email_taken')} />
          )}

          {(createAccountMutation.isError || emailCheckMutation.isError) && (
            <AlertBox error title={t('create_account.errors.default')} />
          )}

          <FormField
            name="email"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('create_account.email')}</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    disabled
                    required
                    autoFocus
                    autoComplete="email"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex flex-row gap-8">

            <FormField
              name="firstName"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('create_account.first_name')}</FormLabel>
                  <FormControl>
                    <Input
                      required
                      maxLength={64}
                      autoComplete="given-name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="lastName"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('create_account.last_name')}</FormLabel>
                  <FormControl>
                    <Input
                      required
                      maxLength={64}
                      autoComplete="family-name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

          </div>

          <FormField
            name="password"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('create_account.password')}</FormLabel>
                <div className="flex flex-row gap-2">
                  <FormControl>
                    <Input
                      type={hidePassword ? 'password' : 'text'}
                      required
                      autoComplete="new-password"
                      {...field}
                    />
                  </FormControl>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="outline"
                          size="icon"
                          type="button"
                          className="h-10 w-11"
                          onClick={() => setHidePassword(prev => !prev)}
                        >
                          {hidePassword
                            ? <EyeSlashIcon className="text-tertiary" />
                            : <EyeIcon className="text-tertiary" />}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>{hidePassword ? t('create_account.show_pass') : t('create_account.hide_pass')}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                <FormDescription>{t('create_account.password_description')}</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            name="acceptToS"
            control={form.control}
            render={({ field }) => (
              <FormItem className="flex flex-row gap-4 items-center">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    required
                  />
                </FormControl>
                <FormLabel className="!mt-0 font-normal">
                  <Trans
                    i18nKey="auth:create_account.accept_tos"
                    components={[
                      <a
                        key="tos"
                        rel="noreferrer"
                        target="_blank"
                        href={config.legal.tosUrl}
                        className="font-medium"
                      />
                    ]}
                  />
                </FormLabel>
              </FormItem>
            )}
          />

          <FormField
            name="acceptEula"
            control={form.control}
            render={({ field }) => (
              <FormItem className="flex flex-row gap-4 items-center">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                    required
                  />
                </FormControl>
                <FormLabel className="!mt-0 font-normal">
                  <Trans
                    i18nKey="auth:create_account.accept_eula"
                    components={[
                      <a
                        key="eula"
                        rel="noreferrer"
                        target="_blank"
                        href={config.legal.eulaUrl}
                        className="font-medium"
                      />
                    ]}
                  />
                </FormLabel>
              </FormItem>
            )}
          />

          <Button
            type="submit"
            className="w-full"
            disabled={!invitation || createAccountMutation.isSuccess || createAccountMutation.isPending}
          >
            {t('create_account.submit')}
          </Button>

          <p className="text-center text-sm text-tertiary">
            <Trans
              i18nKey="auth:create_account.to_login_2"
              components={[ <Link key="login" to="../login" className="font-medium text-primary" /> ]}
            />
          </p>

        </form>
      </Form>

    </div>
  )
}
