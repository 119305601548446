import { init, LanguageDetectorModule, use } from 'i18next'
import { initReactI18next } from 'react-i18next'
import enGeneral from '../locales/en/general.json'
import enAuth from '../locales/en/auth.json'
import enSettings from '../locales/en/settings.json'
import enSupport from '../locales/en/support.json'
import enPartners from '../locales/en/partners.json'
import enCustomers from '../locales/en/customers.json'
import enTeam from '../locales/en/team.json'


// Use a language detector to cache the user's chosen language or fall back to the browser's language
const languageCacheKey = '@cleverev-hub/language'
use<LanguageDetectorModule>({
  type: 'languageDetector',
  init: () => null,
  detect: (): string => {
    const detectedLanguage = navigator.language
    const cachedLanguage = localStorage.getItem(languageCacheKey)

    return cachedLanguage ?? detectedLanguage
  },
  cacheUserLanguage: (language: string): void => {
    localStorage.setItem(languageCacheKey, language)
  }
})

// Use the react-i18next module for react compatibility
use(initReactI18next)

// Default language and namespace
const defaultLng = 'en'
const defaultNS = 'translation'

// Load language resources as constant
const resources = {
  en: {
    general: enGeneral,
    auth: enAuth,
    settings: enSettings,
    support: enSupport,
    partners: enPartners,
    customers: enCustomers,
    team: enTeam
  }
} as const

// Initialize i18next
init({
  compatibilityJSON: 'v4',
  defaultNS,
  fallbackLng: defaultLng,
  interpolation: {
    escapeValue: false
  },
  ns: [ defaultNS ],
  resources
})

export { defaultLng, defaultNS, resources }
